import Vue from 'vue';
import Vuex from 'vuex';
import Employee from './Employee'

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        Employee
    },
    namespace: true,
    state: {
        drawer: true,
        isAuth: true,
        // Employee:null,
    },
    mutations: {
        toggleDrawer(state) {
            state.drawer = !state.drawer;
        },
        toggleAuth(state) {
            state.isAuth = !state.isAuth;
        },
        SET_AUTH(state, data) {
            state.isAuth = data;
        }
    },
    actions: {
        TOGGLE_DRAWER({ commit }) {
            commit('toggleDrawer');
        }
    },
    getters: {
        DRAWER_STATE(state) {
            return state.drawer;
        },
        getAuth(state) {
            return state.isAuth;
        }
    }
});