<template>
  <v-container fluid>
    <div class="dashboard-page">
      <v-row no-gutters class="d-flex justify-space-between mt-10 mb-6">
        <h1 class="page-title">Deliverable</h1>
      </v-row>
    </div>
    <v-card class="mt-5 pa-6" style="position: relative">
      <v-overlay absolute :z-index="100" :value="isFetching" :opacity="0.8">
        <v-progress-circular size="80" width="5" indeterminate color="blue" />
      </v-overlay>
      <h5 class="mb-6">Deliverable Settings</h5>
      <v-container fluid>
        <v-row align="center">
          <v-col class="d-flex" cols="12" sm="6">
            <v-select
              v-model="teamSetting"
              :items="options"
              return-object
              item-text="name"
              item-value="value"
              label="Team Deliverable Setting"
              outlined
            />
          </v-col>

          <v-col class="d-flex" cols="12" sm="6">
            <v-select
              v-model="projectPlanSetting"
              :items="options"
              return-object
              item-text="name"
              item-value="value"
              label="Project Plan Setting"
              outlined
            />
          </v-col>

          <v-col class="d-flex" cols="12" sm="6">
            <v-select
              v-model="myTasksSetting"
              :items="options"
              return-object
              item-text="name"
              item-value="value"
              label="My Tasks Setting"
              outlined
            />
          </v-col>

          <v-col class="d-flex" cols="12" sm="6">
            <v-select
              v-model="FAQSetting"
              :items="options"
              return-object
              item-text="name"
              item-value="value"
              label="FAQ Setting"
              outlined
            />
          </v-col>

          <v-col class="d-flex" cols="12" sm="6">
            <v-select
              v-model="digitalResumeSetting"
              :items="options"
              return-object
              item-text="name"
              item-value="value"
              label="Digital Resume Setting"
              outlined
            />
          </v-col>

          <v-col class="d-flex" cols="12" sm="6">
            <v-select
              v-model="finalPresentationSetting"
              :items="options"
              return-object
              item-text="name"
              item-value="value"
              label="Final Presentation Setting"
              outlined
            />
          </v-col>

          <v-col class="d-flex" cols="12" sm="6">
            <v-select
              v-model="marketingBlogSetting"
              :items="options"
              return-object
              item-text="name"
              item-value="value"
              label="Marketing Blog Setting"
              outlined
            />
          </v-col>

          <v-col class="d-flex" cols="12" sm="6">
            <v-select
              v-model="projectDocumentationSetting"
              :items="options"
              return-object
              item-text="name"
              item-value="value"
              label="Project Documentation Setting"
              outlined
            />
          </v-col>

          <v-col class="d-flex" cols="12" sm="6">
            <v-select
              v-model="userInterviewSetting"
              :items="options"
              return-object
              item-text="name"
              item-value="value"
              label="User Interview Setting"
              outlined
            />
          </v-col>
        </v-row>
        <v-row
          class="my-4 pa-3 d-flex flex-column justify-start align-content-start"
        >
          <v-btn
            x-large
            :loading="isLoading"
            dark
            color="blue"
            @click="updateDeliverableSettings"
          >
            Save Settings
          </v-btn>
          <v-alert
            v-if="msg.length"
            :color="isSuccess ? 'green' : 'red'"
            dense
            class="mt-3 white--text presets__alert"
          >
            {{ msg }}
          </v-alert>
        </v-row>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import API from "../../config";

export default {
  name: "Deliverable",
  data() {
    return {
      teamSetting: "",
      projectPlanSetting: "",
      myTasksSetting: "",
      FAQSetting: "",
      digitalResumeSetting: "",
      finalPresentationSetting: "",
      marketingBlogSetting: "",
      projectDocumentationSetting: "",
      userInterviewSetting: "",
      isLoading: false,
      isFetching: false,
      options: [
        { name: "Let Host Select", value: "host" },
        { name: "Globally On", value: "global_on" },
        { name: "Globally Off", value: "global_off" },
      ],
      isSuccess: true,
      msg: "",
    };
  },
  async created() {
    await this.fetchDeliverableAdminSetting();
  },
  methods: {
    async updateDeliverableSettings() {
      this.isLoading = true;
      try {
        const data = {
          teamSetting: this.teamSetting.value || "",
          projectPlanSetting: this.projectPlanSetting.value || "",
          myTasksSetting: this.projectPlanSetting.value || "",
          FAQSetting: this.FAQSetting.value || "",
          digitalResumeSetting: this.digitalResumeSetting.value || "",
          finalPresentationSetting: this.finalPresentationSetting.value || "",
          marketingBlogSetting: this.marketingBlogSetting.value || "",
          projectDocumentationSetting:
            this.projectDocumentationSetting.value || "",
          userInterviewSetting: this.userInterviewSetting.value || "",
        };
        console.log(data);
        let token = await localStorage.getItem("UserToken");
        let objectToken = JSON.parse(token);
        let accessToken = await objectToken[0].access_token;
        const res = await axios.patch(
          `${API.VUE_APP_DELIVERABLE_ADMIN}?type=deliverable_settings`,
          data,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        if (res.data.data) {
          this.isLoading = false;
          this.msg = "Deliverables Settings updated successfully";
          this.isSuccess = true;
        }
      } catch (error) {
        console.log(error);
        this.isLoading = false;
        this.msg = "Something went wrong";
        this.isSuccess = false;
      }
    },

    async fetchDeliverableAdminSetting() {
      try {
        let token = await localStorage.getItem("UserToken");
        let objectToken = JSON.parse(token);
        let accessToken = await objectToken[0].access_token;
        this.isFetching = true;
        const ENDPOINT_API = process.env.VUE_APP_DELIVERABLE_ADMIN;

        const res = await axios.get(
          `${ENDPOINT_API}?type=deliverable_setting`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        if (res.data.data && res.data.data.deliverableSettings) {
          const settingData = res.data.data.deliverableSettings;
          this.teamSetting = this.options.filter(
            (i) => i.value === settingData.teamSetting
          )[0];
          this.projectPlanSetting = this.options.filter(
            (i) => i.value === settingData.projectPlanSetting
          )[0];
          this.myTasksSetting = this.options.filter(
            (i) => i.value === settingData.myTasksSetting
          )[0];
          this.FAQSetting = this.options.filter(
            (i) => i.value === settingData.FAQSetting
          )[0];
          this.digitalResumeSetting = this.options.filter(
            (i) => i.value === settingData.digitalResumeSetting
          )[0];
          this.finalPresentationSetting = this.options.filter(
            (i) => i.value === settingData.finalPresentationSetting
          )[0];
          this.marketingBlogSetting = this.options.filter(
            (i) => i.value === settingData.marketingBlogSetting
          )[0];
          this.projectDocumentationSetting = this.options.filter(
            (i) => i.value === settingData.projectDocumentationSetting
          )[0];
          this.userInterviewSetting = this.options.filter(
            (i) => i.value === settingData.userInterviewSetting
          )[0];
          this.isFetching = false;
        }
      } catch (error) {
        console.log(error);
        this.isFetching = false;
      }
    },
  },
};
</script>
