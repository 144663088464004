<template>
  <v-container fluid>
    <div class="dashboard-page">
      <v-row no-gutters class="d-flex justify-space-between mt-10 mb-6">
        <h1 class="page-title">Dashboard</h1>
      </v-row>
    </div>
    <div class="text-center mt-5">
      <h1 class="text-center my-12">Welcome</h1>
    </div>
  </v-container>
</template>

<script>
import mock from "./mock";

export default {
  name: "Dashboard",
  data() {
    return {
      mock,
      apexLoading: false,
      value: this.getRandomInt(10, 90),
      value2: this.getRandomInt(10, 90),
      mainApexAreaSelect: "Daily",
      isAuth: false,
    };
  },
  methods: {
    getRandomDataForTrends() {
      const arr = [];
      for (let i = 0; i < 12; i += 1) {
        arr.push(Math.random().toFixed(1) * 10);
      }
      return arr;
    },
    generatePieSeries() {
      let series = [];

      for (let i = 0; i < 4; i++) {
        let y = Math.floor(Math.random() * (500 - 100 + 100)) + 100;
        series.push(y);
      }
      return series;
    },
    getRandomInt(min, max) {
      let rand = min - 0.5 + Math.random() * (max - min + 1);
      return Math.round(rand);
    },
  },
  mounted() {
    this.isAuth = this.$store.getters.getAuth;
    if (!this.isAuth) {
      this.$router.push("/login");
      setTimeout(() => {
        this.apexLoading = true;
      });
    }
  },
};
</script>

<style src="./Dashboard.scss" lang="scss"/>
