<template>
  <div>
    <!-- Snackbar -->
    <v-snackbar
      persistent
      top
      right
      height="20px"
      :color="res !== '' ? 'success' : 'red'"
      v-model="snackbar"
    >
      <div class="d-flex justify-space-between">
        <p class="mb-2">
          <span v-if="res !== ''">Students Csv Downloaded Successfully!</span>
          <span v-else> Students not found! </span>
        </p>
        <div>
          <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
        </div>
      </div>
    </v-snackbar>
    <!-- Download Buttons -->
    <v-row align="center" justify="space-between" class="mt-3 mb-2">
      <div>
        <v-btn
          class="mx-2 white-text"
          width="auto"
          height="40"
          mr-18
          dark
          depressed
          small
          color="#ae90b0"
          @click="overAlldownloadData"
        >
          Overall Data Download
        </v-btn>
        <v-btn
          class="mx-2"
          width="auto"
          height="40"
          dark
          depressed
          small
          color="#6eba7f"
          @click="downloadData"
        >
          Download
        </v-btn>
      </div>
    </v-row>
    <!-- Search -->
    <template>
      <v-row>
        <v-col cols="12" sm="6" class="mt-5">
          <v-text-field
            v-model="search"
            hint="search"
            label="Search Program Name"
            v-on:keyup.enter="searchData"
          ></v-text-field>
        </v-col>
        <v-col class="mt-8">
          <v-btn dark medium color="blue" @click="searchData"> SEARCH </v-btn>
          <v-btn dark medium color="blue" class="ml-3" @click="resetValue">
            RESET
          </v-btn>
        </v-col>
      </v-row>
    </template>
    <!-- Progress bar -->
    <div class="text-center">
      <v-progress-circular
        v-show="getLoad"
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <!-- V-data-table -->
    <v-data-table
      :headers="headers"
      :items="itemsWithSno"
      :items-per-page="valuePerPage"
      :server-items-length="totalCount"
      class="elevation-1"
      :page.sync="pagination.page"
      @update:options="filterApply"
    >
      <template v-slot:[`item.publishedFormat`]="{ item }">
        <v-chip :color="getColor(item.publishedFormat)" dark>
          {{ item.publishedFormat }}
        </v-chip>
      </template>
      <template v-slot:[`item.programName`]="{ item }">
        <div hidden>{{ item.url }}</div>
          <a
            v-if="item.programName !== '-'"
            style="text-decoration: none; color: blue"
            target="_blank"
            :href="item.url"
            >{{ item.programName }}</a
          >
          <a v-else>{{ item.programName }}</a>
      </template>
      <template slot="item.view" slot-scope="props">
        <v-btn
          class="mx-2"
          color="#ea6763"
          dark
          small
          @click="() => programDetails(props.item)"
          >view
        </v-btn>
      </template>
      <template slot="item.csv" slot-scope="props">
        <v-btn
          class="mx-2"
          color="primary"
          small
          @click="() => downloadStudentsCsv(props.item._id)"
          >Download CSV
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      url: null,
      programName: null,
      dateCreated: null,
      lastSaved: null,
      search: "",
      calories: "",
      valuePerPage: 10,
      pagination: {},
      shortField: null,
      page: 1,
      start: 0,
      end: 5,
      sortDesc: false,
      spinner: false,
      sponserName: "",
      res: "",
      resData: "",
      snackbar: false,
    };
  },
  watch: {
    valuePerPage: function () {
      if (this.shortField) {
        console.log("in shortField condition");
        let p = {
          sortDesc: this.sortDesc,
          field: this.shortField,
          start: 0,
          limit: this.valuePerPage,
        };
        this.fetchFilterPrograms(p);
      } else {
        let perPage = this.valuePerPage;
        console.log("in else condition", this.valuePerPage);
        if (this.valuePerPage < 0) {
          perPage = this.getProgramsData.meta.total_count;
        }
        let p = { start: 0, limit: perPage };
        this.fetchProgramsData(p);
      }
    },

    shortField: function () {
      this.spinner = true;
      if (this.shortField) {
        console.log("in shortField condition");
        let p = {
          sortDesc: this.sortDesc,
          field: this.shortField,
          start: this.valuePerPage * this.page - this.valuePerPage,
          limit: this.valuePerPage,
        };
        this.fetchFilterPrograms(p);
      } else {
        console.log("in else condition");
        let p = { start: 0, limit: this.valuePerPage };
        this.fetchProgramsData(p);
      }
      this.spinner = false;
    },

    page: function async() {
      this.spinner = true;
      console.log(this.shortField, "in page- valuePerPage");
      if (this.search && this.search.length > 2) {
        let p = {
          start: this.valuePerPage * this.page - this.valuePerPage,
          limit: this.valuePerPage,
          search: this.search,
        };
        this.fetchProgramSearch(p);
      } else if (this.shortField) {
        console.log("in shortField condition");
        let p = {
          sortDesc: this.sortDesc,
          field: this.shortField,
          start: this.valuePerPage * this.page - this.valuePerPage,
          limit: this.valuePerPage,
        };
        this.fetchFilterPrograms(p);
      } else {
        console.log("in else condition", this.valuePerPage);
        let p = {
          start: this.valuePerPage * this.page - this.valuePerPage,
          limit: this.valuePerPage,
        };
        this.fetchProgramsData(p);
      }
      this.spinner = false;
    },

    sortDesc: function () {
      this.spinner = true;
      let p = {
        sortDesc: this.sortDesc,
        field: this.shortField,
        start: this.valuePerPage * this.page - this.valuePerPage,
        limit: this.valuePerPage,
      };
      this.fetchFilterPrograms(p);
      this.spinner = false;
    },
  },
  computed: {
    headers() {
      return [
        { text: "Serial No", value: "sno", sortable: false },
        { text: "Program Name", value: "programName" },
        { text: "Participants", value: "participantsFormat", sortable: false },
        { text: "Created At", value: "dateCreated" },
        { text: "Modified At", value: "lastSaved" },
        { text: "Published", value: "publishedFormat", sortable: false },
        { text: "", value: "view", sortable: false },
        { text: "", value: "csv" },
      ];
    },
    // searchTrigger() {
    //   return this.search.length < 4 ? "" : this.search;
    // },
    body() {
      return this.getProgramsData.data;
    },
    itemsWithSno() {
      return this.body.map((items, index) => ({
        ...items,
        dateCreated: items.dateCreated
          ? new Date(items.dateCreated).toLocaleString()
          : "-",
        lastSaved: items.lastSaved
          ? new Date(items.lastSaved).toLocaleString()
          : "-",
        publishedFormat: items.published ? "Active" : "Inactive",
        programName: items.programName ? items.programName : "-",
        participantsFormat: items.participants ? items.participants.length : 0,
        urlFormat: "https://pilotcity.com/monitor?program=" + items._id,
        url: "https://pilotcity.com/monitor?program=" + items._id,
        sno: index + 1 + (this.page - 1) * this.valuePerPage,
      }));
    },
    ...mapGetters("Employee", ["getProgramsData", "getLoad", "getTotalCount"]),
    totalCount() {
      if (this.search && this.search !== null) {
        return this.getTotalCount;
      } else {
        return this.getProgramsData.meta.total_count;
      }
    },
    ...mapGetters("Employee", ["getStudentsCsvLink"]),
  },
  methods: {
    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleUpperCase().indexOf(search) !== -1
      );
    },
    updateIron(val) {
      console.log(val, "updateIron");
    },
    getColor(calories) {
      if (calories == "Active") return "green";
      else return "red";
    },
    filterApply(value) {
      console.log(value, "filteapply");
      if (value == "eventLog[0].event") {
        this.shortField = "createdAt";
      } else {
        this.shortField = value.sortBy[0];
      }
      this.valuePerPage = value.itemsPerPage;
      this.page = value.page;
      this.sortDesc = value.sortDesc[0];
    },
    programDetails(val) {
      this.$router.push("program-details/" + val._id);
    },
    fetchDataGrid() {
      this.spinner = false;
    },
    async exportData() {
      let p = {
        sortDesc: this.sortDesc || "",
        field: this.shortField || "",
        start: this.valuePerPage * this.page - this.valuePerPage,
        limit: this.valuePerPage,
        search: this.searchTrigger || "",
      };
      return await this.fetchProgramsExport(p);
    },
    async downloadData() {
      const link = await this.exportData();
      if (link) {
        this.res = link;
        window.open(link, "_blank");
      }
      this.snackbar = true;
    },
    async overAlldownloadData() {
      const link = await this.fetchProgramsOverAllExport();
      if (link) {
        this.res = link;
        window.open(link, "_blank");
      }
      this.snackbar = true;
    },
    async downloadStudentsCsv(programId) {
      console.log("programId", programId);
      const link = await this.fetchStudentsExport(programId);
      console.log(link, "link");
      if (link) {
        this.res = link;
        window.open(link, "_blank");
        this.res = "";
      }
      this.snackbar = true;
    },
    searchData() {
      console.log(this.search);
      if (this.search.length >= 4) {
        this.spinner = true;
        this.page = 1;
        let p = {
          start: this.valuePerPage * this.page - this.valuePerPage,
          limit: this.valuePerPage,
          search: this.search,
        };
        this.fetchProgramSearch(p);
        this.fetchProgramSearchLength(p);
        this.spinner = false;
      } else if (this.search.length < 1) {
        let p = {
          start: 0,
          limit: this.valuePerPage,
        };
        this.page = 1;
        this.search = "";
        this.fetchProgramsData(p);
      }
    },
    resetValue() {
      let p = {
        start: 0,
        limit: this.valuePerPage,
      };
      this.page = 1;
      this.fetchProgramsData(p);
      this.search = "";
    },
    ...mapActions("Employee", [
      "fetchProgramsData",
      "fetchFilterPrograms",
      "fetchStudentsExport",
      "fetchProgramsExport",
      "fetchProgramsOverAllExport",
      "fetchProgramSearch",
      "fetchProgramSearchLength",
    ]),
  },
  created() {
    if (this.getProgramsData == null) {
      console.log("call home & export api");
      let p = {
        start: this.valuePerPage * this.page - this.valuePerPage,
        limit: this.valuePerPage,
      };
      this.fetchProgramsData(p);
    }
    this.isAuth = this.$store.getters.getAuth;
    if (!this.isAuth) {
      this.$router.push("/login");
      setTimeout(() => {
        this.apexLoading = true;
      });
    }
  },
};
</script>

<style scoped>
.typeClass {
  display: flex !important;
}

.type-val {
  display: flex;
  background-color: #90caf9;
}

.section-calories {
  color: green !important;
}

.select {
  width: 60px;
  max-height: 60px;
  font-size: 11px;
}

.v-input.theme--light.v-text-field.v-text-field--is-booted.v-text-field--enclosed.v-text-field--outlined.v-text-field--placeholder.v-select {
  max-width: 25%;
}

table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
}
th,
td {
  padding: 5px;
  text-align: left;
}
</style>
