var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{staticClass:"main-header",attrs:{"height":"64","fixed":"","color":"#4f4f4f","dark":""}},[_c('v-btn',{staticClass:"mx-1",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.TOGGLE_DRAWER.apply(null, arguments)}}},[(_vm.DRAWER_STATE)?[_c('v-icon',{staticStyle:{"font-size":"28px"}},[_vm._v("mdi-arrow-left")])]:[_c('v-icon',{staticStyle:{"font-size":"28px"}},[_vm._v("mdi-menu")])]],2),_c('v-toolbar-title',[_vm._v("PilotCity")]),_c('v-spacer'),_c('v-menu',{attrs:{"offset-y":"","bottom":"","nudge-bottom":"10","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",staticStyle:{"font-size":"28px"},attrs:{"icon":""},on:{"click":function($event){_vm.notificationsBadge ? (_vm.notificationsBadge = !_vm.notificationsBadge) : ''}}},'v-btn',attrs,false),on),[_c('v-badge',{attrs:{"value":_vm.notificationsBadge,"color":"error","content":"4","overlap":""}},[_c('v-icon',{staticStyle:{"font-size":"28px"},attrs:{"color":"rgba(255, 255, 255, 0.35)"}},[_vm._v("mdi-bell-outline")])],1)],1)]}}])},[_c('v-list',[_c('v-list-item-group',{attrs:{"color":"primary"}},_vm._l((_vm.notifications),function(item,i){return _c('v-list-item',{key:i},[_c('v-list-item-icon',{staticClass:"mr-4 mb-1"},[_c('v-icon',{attrs:{"color":item.color},domProps:{"textContent":_vm._s(item.icon)}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.text)}})],1)],1)}),1)],1)],1),_c('v-menu',{attrs:{"max-width":"280","offset-y":"","bottom":"","nudge-bottom":"10","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",staticStyle:{"font-size":"28px"},attrs:{"icon":""},on:{"click":function($event){_vm.messageBadge ? (_vm.messageBadge = !_vm.messageBadge) : ''}}},'v-btn',attrs,false),on),[_c('v-badge',{attrs:{"value":_vm.messageBadge,"color":"warning","content":"3","overlap":""}},[_c('v-icon',{staticStyle:{"font-size":"28px"},attrs:{"color":_vm.config.light.iconColor}},[_vm._v(" mdi-email-outline")])],1)],1)]}}])},[_c('v-list',[_c('div',{staticClass:"text-h5 grey--text text--darken-3 px-4 pt-4"},[_vm._v(" New Messages ")]),_c('div',{staticClass:"subtitle-2 error--text font-weight-regular px-4 pb-2 link-item"},[_vm._v(" 4 new Messages ")]),_c('v-list-item-group',{attrs:{"color":"primary"}},_vm._l((_vm.messages),function(item,i){return _c('v-list-item',{key:i},[_c('v-list-item-icon',{staticClass:"mr-4 mb-1 d-flex flex-column"},[_c('v-btn',{attrs:{"fab":"","x-small":"","depressed":"","color":item.color}},[_vm._v(_vm._s(item.text)+" ")]),_c('div',{staticStyle:{"font-size":"11px"}},[_vm._v(_vm._s(item.time))])],1),_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',{staticStyle:{"width":"190px"}},[_c('v-list-item-title',{staticClass:"pb-2",domProps:{"textContent":_vm._s(item.name)}}),_c('v-list-item-subtitle',{staticClass:"text-truncate",domProps:{"textContent":_vm._s(item.message)}})],1)],1)],1)}),1),_c('div',{staticClass:"d-flex justify-center my-3"},[_c('v-btn',{staticClass:"text-capitalize",attrs:{"large":"","color":"primary","rounded":"","elevation":"5"}},[_vm._v(" Send New Message "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v("mdi-send")])],1)],1)],1)],1),_c('v-menu',{attrs:{"min-width":"180","offset-y":"","bottom":"","left":"","nudge-bottom":"10"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-0",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticStyle:{"font-size":"28px"},attrs:{"color":_vm.config.light.iconColor}},[_vm._v("mdi-account")])],1)]}}])},[_c('v-list',[_c('div',{staticClass:"text-h5 grey--text text--darken-3 px-4 pt-4"},[_vm._v(" John Smith ")]),_c('div',{staticClass:"subtitle-2 primary--text font-weight-regular px-4"},[_vm._v(" Flatlogic.com ")]),_c('v-list-item-group',{attrs:{"color":"primary"}},_vm._l((_vm.account),function(item,i){return _c('v-list-item',{key:i},[_c('v-list-item-icon',{staticClass:"mr-4"},[_c('v-icon',{attrs:{"color":item.color},domProps:{"textContent":_vm._s(item.icon)}})],1),_c('v-list-item-content',[_c('v-list-item-title',{attrs:{"color":_vm.config.light.textColor},domProps:{"textContent":_vm._s(item.text)}})],1)],1)}),1),_c('div',{staticClass:"d-flex justify-center my-3"},[_c('v-btn',{staticClass:"text-capitalize",attrs:{"width":"80%","large":"","outlined":"","color":"primary"},on:{"click":_vm.logOut}},[_vm._v("Sign Out ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }