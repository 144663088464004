<template>
  <v-container class="mt-7">
    <h3>Aggrement</h3>
    <ckeditor :editor="editor" v-model="content"></ckeditor>
    <v-row justify="center" class="my-auto">
      <v-btn
        class="ma-2"
        color="primary"
        @click="setData(content)"
        :loading="isLoading"
      >
        Submit
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "axios";
import API from "../../config";

export default {
  name: "Agreement",
  data() {
    return {
      editor: ClassicEditor,
      content: "<p>content data.</p>",
      isLoading: false,
      editorConfig: {
        // The configuration of the editor.
      },
    };
  },
  methods: {
    async setData(data) {
      this.isLoading = true;
      const token = await localStorage.getItem("UserToken");
      const tokenObject = JSON.parse(token);
      const accessToken = await tokenObject[0].access_token;
      await axios
        .put(
          `${API.AGREEMENT_API}/agreement`,
          { agreement_text: data },
          {
            headers: {
              authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then((response) => {
          console.log(response);
          this.content = response.data.agreement_consent_text;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error.response);
          this.isLoading = false;
        });
    },
    async fetchContentData() {
      let res;
      await axios
        .get(`${API.AGREEMENT_API}/agreement`)
        .then((response) => {
          console.log(response, "data");
          res = response.data.agreement_consent_text;
        })
        .catch((error) => {
          console.log(error.response);
        });
      return res;
    },
  },
  async created() {
    let res = await this.fetchContentData();
    if (res) {
      this.content = res;
    }
  },
};
</script>

<style>
.ck-editor__editable {
  min-height: 500px;
}
</style>
