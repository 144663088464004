<template>
  <div>
    <div class="text-center">
      <h4 class="text-center my-12">
        Program Info
        <span>{{ $route.params.id }}</span>
        <v-progress-circular
          :size="70"
          :width="7"
          center
          color="purple"
          v-if="getProgramListLoad"
          indeterminate
        ></v-progress-circular>
      </h4>
    </div>
    <template v-if="getProgramList.data">
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
      <v-data-table
        :ref="myTable"
        :headers="headers"
        :items="itemsWithSno"
        :search="search"
        :items-per-page="5"
        class="elevation-1"
      >
        <template v-slot:[`item.publishedFormat`]="{ item }">
          <v-chip :color="getColor(item.publishedFormat)" dark>
            {{ item.publishedFormat }}
          </v-chip>
        </template>
        <template v-slot:[`item.programNameFormat`]="{ item }">
          <div hidden>{{ item.url }}</div>
          <a
            v-if="item.programNameFormat !== '-'"
            style="text-decoration: none; color: blue"
            target="_blank"
            :href="item.url"
            >{{ item.programNameFormat }}</a
          >
          <a v-else>{{ item.programNameFormat }}</a>
        </template>
        <template slot="item.csv" slot-scope="props">
          <v-btn
            class="mx-2"
            color="primary"
            small
            @click="() => downloadStudentsCsv(props.item._id)"
            >Download CSV
          </v-btn>
        </template>
      </v-data-table>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "detail",
  data() {
    return {
      res: "",
      snackbar: false,
      myTable: "",
      url: "https://pilotcity.com/monitor?program=" + this.$route.params.id,
      sno: 1,
      url_data: null,
      search: "",
      headers: [
        { text: "Serial No", value: "sno" },
        {
          text: "Program Name",
          value: "programNameFormat",
        },
        { text: "Participants", value: "participantsFormat" },
        { text: "Role on Program", value: "userRole" },
        { text: "Created At", value: "dateCreatedFormat" },
        { text: "Modified At", value: "lastSavedFormat" },
        { text: "Published", value: "publishedFormat" },
        { text: "", value: "csv" },
      ],
    };
  },

  computed: {
    body() {
      return this.getProgramList.data;
    },
    itemsWithSno() {
      return this.body.map((items, sno) => ({
        ...items,
        dateCreatedFormat: items.dateCreated
          ? new Date(items.dateCreated).toLocaleString()
          : "-",
        lastSavedFormat: items.lastSaved
          ? new Date(items.lastSaved).toLocaleString()
          : "-",
        publishedFormat: items.published ? "Active" : "Inactive",
        programNameFormat: items.programName ? items.programName : "-",
        participantsFormat: items.participants ? items.participants.length : 0,
        urlFormat: "https://pilotcity.com/monitor?program=" + items._id,
        userRole: items.userRole,
        sno: sno + 1,
      }));
    },
    ...mapGetters("Employee", [
      "getProgramList",
      "getProgramListLoad",
      "getprogramListError",
    ]),
  },
  methods: {
    getColor(calories) {
      if (calories == "Active") return "green";
      else return "red";
    },
    ...mapActions("Employee", [
      "fetchProgramList",
      "fetchStudentsExport",
      "getStudentsCsvLink",
    ]),
  },
  mounted() {
    console.log("mounted starts");
    // this.url_data = await this.$route.params.id;
    console.log(this.url_data, "url-data");
    let p = this.$route.params.id;
    this.fetchProgramList(p);
    console.log("mounted end");
  },
};
</script>

<style scoped>
table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
}
th,
td {
  padding: 5px;
  text-align: left;
}
</style>
