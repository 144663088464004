var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('div',{staticClass:"d-flex img-size"},[_c('v-img',{attrs:{"src":require("@/assets/pioletcity.png")}})],1),_c('v-col',{staticClass:"login-part d-flex align-center justify-center",attrs:{"cols":"12","lg":"6"}},[_c('v-row',{staticClass:"align-start",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"login-part d-flex align-center justify-center flex-column",attrs:{"cols":"12"}},[_c('div',{staticClass:"login-wrapper pt-md-4 pt-0"},[_c('h1',[_vm._v("Login")]),_c('v-container',[_c('v-row',{staticClass:"flex-column"},[_c('v-form',[_c('v-col',[_c('ValidationProvider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"rules":errors,"prepend-inner-icon":"mdi-email","label":"Email Address","required":""},model:{value:(_vm.UserEmail),callback:function ($$v) {_vm.UserEmail=$$v},expression:"UserEmail"}})]}}])}),_c('ValidationProvider',{attrs:{"rules":"required|min:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"rules":errors,"prepend-inner-icon":"mdi-lock","type":_vm.passwordShow ? 'text' : 'password',"label":"Password","append-icon":_vm.passwordShow ? 'mdi-eye' : 'mdi-eye-off',"hint":"At least 6 characters"},on:{"click:append":function($event){_vm.passwordShow = !_vm.passwordShow}},model:{value:(_vm.UserPassword),callback:function ($$v) {_vm.UserPassword=$$v},expression:"UserPassword"}})]}}])})],1),_c('v-col',{staticClass:"d-flex justify-space-between"},[_c('v-btn',{staticClass:"text-capitalize",attrs:{"large":"","loading":_vm.Loading,"disabled":_vm.UserPassword.length === 0 || _vm.UserEmail.length === 0,"color":"primary"},on:{"click":_vm.Login}},[_vm._v(" Login")]),_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"max-width":"540px","hide-overlay":"","transition":"fab-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-capitalize primary--text mt-3",attrs:{"large":"","text":""}},'v-btn',attrs,false),on),[_vm._v(" Forget Password ")])]}}]),model:{value:(_vm.Dialog),callback:function ($$v) {_vm.Dialog=$$v},expression:"Dialog"}},[_c('v-card',[_c('v-spacer'),_c('v-btn',{attrs:{"dark":"","icon":"","right":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-close")])],1),_c('div',{staticClass:"text-center"},[_c('h2',{staticClass:"mb-2"},[_vm._v("Forgot Password")]),_c('p',[_vm._v(" We will send a password reset link to the email address of the connected account. ")])]),_c('v-form',[_c('ValidationProvider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-0",attrs:{"label":"Enter your email address","name":"email","rules":errors,"prepend-inner-icon":"mdi-email","type":"text","outlined":""},model:{value:(_vm.ResetPassword),callback:function ($$v) {_vm.ResetPassword=$$v},expression:"ResetPassword"}})]}}])}),_c('v-btn',{staticClass:"text-capitalize",attrs:{"disabled":_vm.ResetPassword.length === 0,"color":"primary","x-large":"","block":""},on:{"click":function($event){return _vm.ResetPass()}}},[_c('sync-loader',{staticClass:"px-8",attrs:{"loading":_vm.Loading,"color":"white"}}),_vm._v(" "+_vm._s(_vm.Loading != true ? "Reset Password" : "")+" ")],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)]),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12"}})],1)],1)],1)],1),_c('v-snackbar',{attrs:{"top":"","right":"","color":_vm.Message != '' ? 'green' : 'red'},model:{value:(_vm.SnackBar),callback:function ($$v) {_vm.SnackBar=$$v},expression:"SnackBar"}},[_vm._v(" "+_vm._s(_vm.Message != "" ? _vm.Message : _vm.errorMessage)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }