import Vue from 'vue'
import App from './App.vue'
import router from './Routes'
import store from './store/index'
import vuetify from './plugins/vuetify'
import * as VueGoogleMaps from 'vue2-google-maps';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import CKEditor from '@ckeditor/ckeditor5-vue2';


Vue.use(Toast);

Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyB7OXmzfQYua_1LEhRdqsoYzyJOPh9hGLg',
    },
});

Vue.use(CKEditor);

Vue.config.productionTip = false

new Vue({
    vuetify,
    router,
    render: h => h(App),
    store
}).$mount('#app')