import axios from "axios";
import API from "../config"

export default {
    namespaced: true,
    state() {
        return {
            usersData: null,
            programsData: null,
            load: false,
            usersTotalCount: null,
            programsTotalCount: null,
            usersExportLink: null,
            usersOverAllexportLink: null,
            programsExportLink: null,
            programsOverAllexportLink: null,
            programList: [],
            programListLoad: false,
            programListError: '',
            studentsCsvLink: null,
        }
    },

    getters: {
        getUsersData: (state) => state.usersData,
        getProgramsData: (state) => state.programsData,
        getLoad: (state) => state.load,
        getUsersTotalCount: (state) => state.usersTotalCount,
        getProgramsTotalCount: (state) => state.programsTotalCount,
        getUsersExportLink: (state) => state.usersExportLink,
        getUsersOverAllExportLink: (state) => state.usersOverAllexportLink,
        getProgramsExportLink: (state) => state.programsExportLink,
        getProgramsOverAllExportLink: (state) => state.programsOverAllexportLink,
        getProgramList: (state) => state.programList,
        getProgramListLoad: (state) => state.programListLoad,
        getprogramListError: (state) => state.programListError,
        getStudentsCsvLink: (state) => state.studentsCsvLink
    },

    mutations: {
        SET_USERS_DATA(state, data) {
            state.usersData = data;
        },
        SET_PROGRAMS_DATA(state, data) {
            state.programsData = data
        },
        SET_LOAD(state, data) {
            state.load = data;
        },
        SET_USERS_TOTAL_COUNT(state, data) {
            state.usersTotalCount = data
        },
        SET_PROGRAMS_TOTAL_COUNT(state, data) {
            state.programsTotalCount = data
        },
        SET_USERS_EXPORT_LINK(state, data) {
            state.usersExportLink = data
        },
        SET_USERS_OVERALL_EXPORT_LINK(state, data) {
            state.usersOverAllexportLink = data
        },
        SET_PROGRAMS_EXPORT_LINK(state, data) {
            state.programsExportLink = data
        },
        SET_PROGRAMS_OVERALL_EXPORT_LINK(state, data) {
            state.programsOverAllexportLink = data
        },
        SET_STUDENT_EXPORT_LINK(state, data) {
            state.studentsCsvLink = data
        },
        SET_PROGRAM_LIST(state, data) {
            state.programList = data
        },
        SET_PROGRAM_LIST_LOAD(state, data) {
            state.programListLoad = data
        },
        SET_PROGRAM_LIST_ERROR(state, data) {
            state.programListError = data
        },
        SET_ALL_PROGRAMS_EXPORT_LINK(state, data) {
            state.overAllProgramsExportLink = data
        }
    },

    actions: {
        /** Users Actions */
        async updateUserRecord({ commit }, value) {
            commit('SET_LOAD', true);
            try {
                console.log("In Update");
                let data = {
                    firstName: value.fname,
                    lastName: value.lname,
                    userTypes: value.utype
                }
                let token = await localStorage.getItem('UserToken');
                let objectToken = JSON.parse(token);
                let accessToken = await objectToken[0].access_token;
                const response = await axios.patch(`${API.USER_API}/update/user?user_id=${value.id}`, data, {
                    headers: {
                        authorization: `Bearer ${accessToken}`,
                        'content-type': 'application/json'
                    }
                });
                console.log(response.data, "response data");
            } catch (error) {
                console.log(error);
            }
            commit('SET_LOAD', false);
        },
        async fetchStudentsExport({ commit, }, value) {
            commit('SET_LOAD', true);
            console.log("fetch csv link");
            console.log(value, 'export student csv');
            let token = await localStorage.getItem('UserToken');
            let objectToken = JSON.parse(token);
            let accessToken = await objectToken[0].access_token;
            let year = new Date().getFullYear();
            console.log(year, "year");
            let response;
            try {
                response = await axios
                    .get(`${API.USER_API}/students?program_id=${value}&year=${year}`, {
                        headers: {
                            'Authorization': `Bearer ${accessToken}`,
                            'content-type': 'application/json'
                        }
                    });
                console.log(response.data.data, 'export.data');
                commit('SET_STUDENT_EXPORT_LINK', response.data.data);
                commit('SET_LOAD', false);
                return response.data.data;
            } catch (error) {
                commit('SET_LOAD', false);
                console.log(error);
            }
        },
        async fetchUsersExport({ commit, }, value) {
            commit('SET_LOAD', true);
            try {
                console.log(value, "value");
                let sortType = value.sortDesc ? "ascending" : "descending";
                let sortField = value.field ? value.field : "createdAt";
                const exportKeys = ["_id", "email", "firstName", "lastName", "phoneNumber"];
                const queryFilter = () => {
                    if (value.search) {
                        return { "pagination": { "start": value.start, "limit": value.limit }, "search": value.search, "export": exportKeys }
                    }
                    if (value.field && value.utype) {
                        return { "type": value.utype, "sorting": { "field": sortField, "order": sortType }, "pagination": { "start": value.start, "limit": value.limit }, "export": exportKeys }
                    } else if (value.field) {
                        return { "sorting": { "field": sortField, "order": sortType }, "pagination": { "start": value.start, "limit": value.limit }, "export": exportKeys }
                    } else if (value.utype) {
                        return { "type": value.utype, "pagination": { "start": value.start, "limit": value.limit }, "export": exportKeys }
                    } else {
                        return { "pagination": { "start": value.start, "limit": value.limit }, "sorting": { "field": sortField, "order": sortType }, "export": exportKeys }
                    }
                }
                const response = await fetchUsers(queryFilter());
                commit("SET_USERS_EXPORT_LINK", response.data);
                commit('SET_LOAD', false);
                return response.data;
            } catch (error) {
                console.log(error, "error");
            }
            commit('SET_LOAD', false);
        },
        async fetchUsersOverAllExport({ commit, }) {
            commit('SET_LOAD', true);
            try {
                let query = { "export": ["_id", "email", "firstName", "lastName", "phoneNumber"] }
                const response = await fetchUsers(query);
                commit("SET_USERS_OVERALL_EXPORT_LINK", response.data);
                commit('SET_LOAD', false);
                return response.data;
            } catch (error) {
                console.log(error, "error");
            }
            commit('SET_LOAD', false);
        },
        async fetchUsersByUserType({ commit, }, value) {
            try {
                commit('SET_LOAD', true);
                let sortType = value.sortDesc ? "descending" : "ascending";
                let query = await { "type": value.utype, "pagination": { "start": value.start, "limit": value.limit } };
                let queryWithSort = await { "type": value.utype, "sorting": { "field": value.field, "order": sortType }, "pagination": { "start": value.start, "limit": value.limit } };
                let response = await fetchUsers(value.field ? queryWithSort : query);
                commit('SET_USERS_DATA', response)
                commit('SET_LOAD', false);
            } catch (error) {
                console.log(error);
            }
        },
        async fetchUsersLengthByUserType({ commit, }, value) {
            try {
                commit('SET_LOAD', true);
                let sortType = value.sortDesc ? "descending" : "ascending";
                let query = await { "type": value.utype };
                let queryWithSort = await { "type": value.utype, "sorting": { "field": value.field, "order": sortType } };
                let response = await fetchUsers(value.field ? queryWithSort : query);
                commit('SET_USERS_TOTAL_COUNT', response.meta.total_fetched_count)
                commit('SET_LOAD', false);
            } catch (error) {
                console.log(error);
            }
        },
        async fetchUserSearch({ commit, }, value) {
            try {
                commit('SET_LOAD', true);
                let query;
                if (value.userType) {
                    query = await { "type": value.userType, "sorting": { "field": "createdAt", "order": "descending" }, "pagination": { "start": value.start, "limit": value.limit }, "search": value.search };
                } else {
                    query = await { "sorting": { "field": "createdAt", "order": "descending" }, "pagination": { "start": value.start, "limit": value.limit }, "search": value.search };
                }
                let response = await fetchUsers(query);
                commit('SET_USERS_DATA', response)
                commit('SET_LOAD', false);
            } catch (error) {
                console.log(error);
            }
        },
        async fetchUserSearchLength({ commit, }, value) {
            try {
                commit('SET_LOAD', true);
                let query;
                if (value.userType) {
                    query = await { "type": value.userType, "sorting": { "field": "createdAt", "order": "descending" }, "search": value.search };
                } else {
                    query = await { "sorting": { "field": "createdAt", "order": "descending" }, "search": value.search };
                }
                let response = await fetchUsers(query);
                commit('SET_USERS_TOTAL_COUNT', response.meta.total_fetched_count)
                commit('SET_LOAD', false);
            } catch (error) {
                console.log(error);
            }
        },
        async fetchUsersData({ commit, }, value) {
            try {
                commit('SET_LOAD', true);
                let query = await { "sorting": { "field": "createdAt", "order": "descending" }, "pagination": { "start": value.start, "limit": value.limit } };
                let response = await fetchUsers(query);
                commit('SET_USERS_DATA', response)
                commit('SET_LOAD', false);
            } catch (error) {
                console.log(error);
            }
        },
        async fetchFilterUser({ commit }, value) {
            commit('SET_LOAD', true)
            let sortType = value.sortDesc ? "ascending" : "descending";
            let token = await localStorage.getItem('UserToken');
            let objectToken = JSON.parse(token); {
                await console.log(value, 'Token');
                let accessToken = await objectToken[0].access_token;
                let query;
                if (value.search) {
                    query = await { "sorting": { "field": value.field, "order": sortType }, "pagination": { "start": value.start, "limit": value.limit } };
                } else {
                    query = await { "sorting": { "field": value.field, "order": sortType }, "pagination": { "start": value.start, "limit": value.limit }, "search": value.search };
                }
                let q = encodeURIComponent(JSON.stringify(query));
                const customHeaders = await {
                    'content-type': 'application/json',
                };
                await axios
                    .get(`${API.USER_API}/users/list?q=${q}`, {
                        headers: {
                            authorization: `Bearer ${accessToken}`
                        }
                    }, customHeaders).then(response => {
                        console.log(response.data, 'filter-data');
                        console.log(response, "res");
                        commit('SET_USERS_DATA', response.data);
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }
            commit('SET_LOAD', false)
        },
        /** Programs Actions */
        async fetchProgramsData({ commit, }, value) {
            try {
                commit('SET_LOAD', true);
                console.log(value, "value");
                const query = { "sorting": { "field": "dateCreated", "order": "descending" }, "pagination": { "start": value.start, "limit": value.limit } };
                const response = await fetchPrograms(query);
                commit("SET_PROGRAMS_DATA", response);
                commit('SET_LOAD', false);
            } catch (error) {
                console.log(error, "error");
            }
        },
        async fetchFilterPrograms({ commit }, value) {
            try {
                commit('SET_LOAD', true)
                console.log(value, "value");
                const sortType = value.sortDesc ? "descending" : "ascending";
                let query;
                if (value.search) {
                    query = await { "sorting": { "field": value.field, "order": sortType }, "pagination": { "start": value.start, "limit": value.limit } };
                } else {
                    query = await { "sorting": { "field": value.field, "order": sortType }, "pagination": { "start": value.start, "limit": value.limit }, "search": value.search };
                }
                const response = await fetchPrograms(query);
                commit("SET_PROGRAMS_DATA", response);
                commit('SET_LOAD', false)
            } catch (error) {
                console.log(error, "error");
            }
        },
        async fetchProgramSearch({ commit, }, value) {
            try {
                commit('SET_LOAD', true);
                console.log(value, "value");
                let query = await { "pagination": { "start": value.start, "limit": value.limit }, "search": value.search };
                const response = await fetchPrograms(query);
                commit("SET_PROGRAMS_DATA", response);
                commit('SET_LOAD', false)
            } catch (error) {
                console.log(error, "error");
            }
        },
        async fetchProgramSearchLength({ commit, }, value) {
            try {
                commit('SET_LOAD', true);
                console.log(value, "value");
                let query = await { "search": value.search };
                const response = await fetchPrograms(query);
                commit("SET_PROGRAMS_TOTAL_COUNT", response.meta.total_fetched_count);
                commit('SET_LOAD', false);
            } catch (error) {
                console.log(error, "error");
            }
        },
        async fetchProgramsExport({ commit }, value) {
            try {
                console.log(value, "value");
                let sortType = value.sortDesc ? "ascending" : "descending";
                let sortField = value.field ? value.field : "dateCreated";
                const queryFilter = () => {
                    if (value.search) {
                        return { "pagination": { "start": value.start, "limit": value.limit }, "search": value.search, "export": ["_id", "programName", "organizers", "participants", "published", "dateCreated", "lastSaved"] }
                    } else if (value.field) {
                        return { "sorting": { "field": sortField, "order": sortType }, "pagination": { "start": value.start, "limit": value.limit }, "export": ["_id", "programName", "organizers", "participants", "published", "dateCreated", "lastSaved"] }
                    } else {
                        return { "pagination": { "start": value.start, "limit": value.limit }, "sorting": { "field": sortField, "order": sortType }, "export": ["_id", "programName", "organizers", "participants", "published", "dateCreated", "lastSaved"] }
                    }
                }
                const response = await fetchPrograms(queryFilter());
                commit("SET_PROGRAMS_EXPORT_LINK", response.data);
                return response.data;
            } catch (error) {
                console.log(error, "error");
            }
        },
        async fetchProgramsOverAllExport({ commit }) {
            try {
                let query = { "export": ["_id", "programName", "organizers", "participants", "published", "dateCreated", "lastSaved"] }
                const response = await fetchPrograms(query);
                commit("SET_PROGRAMS_OVERALL_EXPORT_LINK", response.data);
                return response.data;
            } catch (error) {
                console.log(error, "error");
            }
        },
        async fetchProgramList({ commit, }, value) {
            console.log(value, '  expppp');
            commit('SET_PROGRAM_LIST', []);
            commit('SET_PROGRAM_LIST_ERROR', '')
            commit('SET_PROGRAM_LIST_LOAD', true)
            let token = await localStorage.getItem('UserToken');
            let objectToken = JSON.parse(token); {
                let accessToken = await objectToken[0].access_token;
                let p = '' + value;
                await axios
                    .get(`${API.PROGRAM_API}/programs/list?user_id=${p}`, {
                        headers: {
                            authorization: `Bearer ${accessToken}`
                        }
                    }).then(response => {
                        console.log(response.data, 'program.data');
                        commit('SET_PROGRAM_LIST', response.data);
                        console.log('RESPONSE :' + JSON.stringify(response.data))
                    })
                    .catch((error) => {
                        console.log("ERROR: " + error.message)
                        commit('SET_PROGRAM_LIST_ERROR', error.message)

                    })
            }
            commit('SET_PROGRAM_LIST_LOAD', false)
        }
    }
}

async function fetchPrograms(query) {
    try {
        const token = await localStorage.getItem('UserToken');
        const tokenObject = JSON.parse(token);
        const accessToken = await tokenObject[0].access_token;
        const encodedQuery = encodeURIComponent(JSON.stringify(query));
        const response = await axios.get(`${API.PROGRAM_API}/programs/list?q=${encodedQuery}`, {
            headers: {
                authorization: `Bearer ${accessToken}`,
                'content-type': 'application/json'
            }
        });
        console.log(response.data, "response data");
        return response.data;
    } catch (error) {
        console.log(error, "error");
    }
}

async function fetchUsers(query) {
    try {
        const token = await localStorage.getItem('UserToken');
        const tokenObject = JSON.parse(token);
        const accessToken = await tokenObject[0].access_token;
        const encodedQuery = encodeURIComponent(JSON.stringify(query));
        const response = await axios.get(`${API.USER_API}/users/list?q=${encodedQuery}`, {
            headers: {
                authorization: `Bearer ${accessToken}`,
                'content-type': 'application/json'
            }
        });
        console.log(response.data, "response data");
        return response.data;
    } catch (error) {
        console.log(error, "error");
    }
}