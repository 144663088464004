<template>
  <div>
    <!-- Snackbar -->
    <v-snackbar
      persistent
      top
      right
      height="20px"
      :color="res !== '' ? 'success' : 'red'"
      v-model="snackbar"
    >
      <div class="d-flex justify-space-between">
        <p class="mb-2">
          <span v-if="res !== ''">Students Csv Downloaded Successfully!</span>
          <span v-else> Programs not found! </span>
        </p>
        <div>
          <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
        </div>
      </div>
    </v-snackbar>
    <div class="text-center">
      <h4 class="text-center my-12">
        Program List for
        <span v-if="$route.params.email !== 'undefined'"
          >{{ $route.params.email }}
        </span>
        <span
          v-if="
            $route.params.firstname !== 'undefined' ||
            $route.params.lastname !== 'undefined'
          "
        >
          ( {{ $route.params.firstname }} {{ $route.params.lastname }} )
        </span>
        <span v-if="$route.params.email == 'undefined'">-</span>
        <br />
        <v-progress-circular
          v-show="getLoad"
          :size="70"
          :width="7"
          center
          color="purple"
          indeterminate
        ></v-progress-circular>
      </h4>
    </div>
    <div v-if="getprogramListError !== ''">
      <h2 class="text-center">
        <v-chip color="red pa-10 white--text font-weight-bold display-1">
          Program List not found with this user_id!
        </v-chip>
      </h2>
    </div>
    <template v-if="getProgramList.data">
      <v-data-table
        :ref="myTable"
        :headers="headers"
        :items="itemsWithSno"
        :search="search"
        :items-per-page="valuePerPage"
        class="elevation-1"
      >
        <template v-slot:[`item.publishedFormat`]="{ item }">
          <v-chip :color="getColor(item.publishedFormat)" dark>
            {{ item.publishedFormat }}
          </v-chip>
        </template>
        <template v-slot:[`item.programNameFormat`]="{ item }">
          <div hidden>{{ item.url }}</div>
          <a
            v-if="item.programNameFormat !== '-'"
            style="text-decoration: none; color: blue"
            target="_blank"
            :href="item.url"
            >{{ item.programNameFormat }}</a
          >
          <a v-else>{{ item.programNameFormat }}</a>
        </template>
        <template slot="item.csv" slot-scope="props">
          <v-btn
            class="mx-2"
            color="primary"
            small
            @click="() => downloadStudentsCsv(props.item._id)"
            >Download CSV
          </v-btn>
        </template>
      </v-data-table>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "detail",
  data() {
    return {
      res: "",
      snackbar: false,
      myTable: "",
      url:
        "https://pilotcity.com/monitor?program=" +
        this.$route.params.id,
      sno: 1,
      url_data: null,
      search: "",
      headers: [
        { text: "Serial No", value: "sno", sortable: false },
        {
          text: "Program Name",
          value: "programNameFormat",
        },
        { text: "Participants", value: "participantsFormat", sortable: false },
        { text: "Role on Program", value: "userRole", sortable: false },
        { text: "Created At", value: "dateCreatedFormat" },
        { text: "Modified At", value: "lastSavedFormat" },
        { text: "Published", value: "publishedFormat", sortable: false },
        { text: "", value: "csv", sortable: false },
      ],
    };
  },

  computed: {
    body() {
      console.log(this.getProgramList.data, "data");
      return this.getProgramList.data;
    },
    itemsWithSno() {
      return this.body.map((items, sno) => ({
        ...items,
        dateCreatedFormat: items.dateCreated
          ? new Date(items.dateCreated).toLocaleString()
          : "-",
        lastSavedFormat: items.lastSaved
          ? new Date(items.lastSaved).toLocaleString()
          : "-",
        publishedFormat: items.published ? "Active" : "Inactive",
        programNameFormat: items.programName ? items.programName : "-",
        participantsFormat: items.participants ? items.participants.length : 0,
        urlFormat: "https://pilotcity.com/monitor?program=" + items._id,
        userRole: items.userRole,
        sno: sno + 1,
      }));
    },
    ...mapGetters("Employee", [
      "getLoad",
      "getProgramList",
      "getStudentsCsvLink",
      "getProgramListLoad",
      "getprogramListError",
    ]),
  },
  methods: {
    getColor(calories) {
      if (calories == "Active") return "green";
      else return "red";
    },
    async downloadStudentsCsv(programId) {
      console.log("programId", programId);
      const link = await this.fetchStudentsExport(programId);
      console.log(link, "link");
      if (link) {
        this.res = link;
        window.open(link, "_blank");
      } else {
        this.res = '';
      }
      console.log(this.res, "res");
      this.snackbar = true;
    },
    ...mapActions("Employee", [
      "fetchProgramList",
      "fetchStudentsExport",
      "getStudentsCsvLink",
    ]),
  },
  mounted() {
    console.log("mounted starts");
    this.url_data = this.$route.params.id;
    console.log(this.url_data, "url-data");
    let p = this.$route.params.id;
    this.fetchProgramList(p);
    console.log("mounted end");
  },
};
</script>

<style scoped>
table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
}
th,
td {
  padding: 5px;
  text-align: left;
}
</style>
