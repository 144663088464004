import Vue from 'vue';
import Router from 'vue-router';

import Layout from '@/components/Layout/Layout';

// Pages
import Dashboard from '@/pages/Dashboard/Dashboard';
import Notifications from '@/pages/Notifications/Notifications'
import Icons from '@/pages/Icons/Icons'
import Charts from '@/pages/Charts/Charts'
import Maps from '@/pages/Maps/Google'
import Error from "@/pages/Error/Error";
import Login from "@/pages/Login/Login";
import Table from '@/pages/Tables/table';
import ProgramTables from '@/pages/ProgramTables/programs';
import ProgramDetails from '@/pages/ProgramTables/programDetails';
import Detail from '@/pages/Detail/Detail';
import Agreement from "@/pages/Agreement/Agreement";
import Deliverable from "@/pages/Deliverable/Deliverable";


Vue.use(Router);

const router = new Router({
    mode: 'history',
    routes: [{
            path: '/login',
            name: 'Login',
            component: Login
        },
        {
            path: '/',
            redirect: '/login',
            name: 'Layout',
            component: Layout,
            children: [{
                    path: '/dashboard',
                    name: 'Dashboard',
                    component: Dashboard,
                },
                {
                    path: 'users',
                    name: 'Table',
                    component: Table
                },
                {
                    path: 'programs',
                    name: 'ProgramTables',
                    component: ProgramTables
                },
                {
                    path: 'program-details/:id?',
                    name: 'ProgramDetails',
                    component: ProgramDetails,
                },
                {
                    path: 'notifications',
                    name: 'Notifications',
                    component: Notifications
                },
                {
                    path: 'icons',
                    name: 'Icons',
                    component: Icons
                },
                {
                    path: 'charts',
                    name: 'Charts',
                    component: Charts
                },
                {
                    path: 'maps',
                    name: 'Maps',
                    component: Maps
                },
                {
                    path: 'detail/:id?/:email?/:firstname?/:lastname?',
                    name: 'detail',
                    component: Detail,
                },
                {
                    path: "agreement",
                    name: "agreement",
                    component: Agreement,
                },
                {
                    path: "deliverable",
                    name: "deliverable",
                    component: Deliverable,
                },
            ],
        },
        {
            path: '*',
            name: 'Error',
            component: Error
        }
    ],
});

export default router;