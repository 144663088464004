require('dotenv').config();
const AUTHENTICATION_API = process.env.VUE_APP_AUTHENTICATION_API;
const PROGRAM_API = process.env.VUE_APP_PROGRAM_API;
const REDIS_API = process.env.VUE_APP_REDIS_API;
const USER_API = process.env.VUE_APP_USER_API;
const AGREEMENT_API = process.env.VUE_APP_AGREEMENT_API;
const VUE_APP_DELIVERABLE_ADMIN = process.env.VUE_APP_DELIVERABLE_ADMIN;

export default {
    light: {
        primary: "#3c9dcd",
        secondary: "#FF5C93",
        error: "#FF4081",
        warning: "#FFC260",
        success: "#3CD4A0",
        info: "#9013FE",
        textColor: "#B9B9B9",
        iconColor: "#FFFFFF59",
        grayBold: '#4a4a4a'
    },
    AUTHENTICATION_API: AUTHENTICATION_API,
    USER_API: USER_API,
    PROGRAM_API: PROGRAM_API,
    REDIS_API: REDIS_API,
    AGREEMENT_API: AGREEMENT_API,
    VUE_APP_DELIVERABLE_ADMIN: VUE_APP_DELIVERABLE_ADMIN
};