<template>
  <div>
    <!-- snackbar -->
    <v-snackbar
      persistent
      top
      right
      height="20px"
      :color="res == 200 || link ? 'success' : 'red'"
      v-model="snackbar"
    >
      <div class="d-flex justify-space-between">
        <p class="mb-2">
          <span v-if="res == 200">Token Successfully Assigned!</span>
          <span v-else-if="res !== 200 && !link"> Error Occured! </span>
        </p>
        <p class="mb-2">
          <span v-if="link">User Csv Downloaded Successfully!</span>
          <span v-else-if="!link && res !== 200"> Users not found! </span>
        </p>
        <div>
          <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
        </div>
      </div>
    </v-snackbar>
    <v-row
      align="center"
      justify="space-between"
      class="d-flex justify-space-between"
    >
      <div class="text-center mt-4">
        <v-btn
          class="mx-2 white-text"
          width="auto"
          height="40"
          dark
          mr-18
          depressed
          small
          color="#ae90b0"
          @click="overAlldownloadData"
        >
          Overall Data Download
        </v-btn>
        <v-btn
          class="mx-2"
          width="auto"
          height="40"
          dark
          depressed
          small
          color="#6eba7f"
          @click="downloadData"
        >
          Download
        </v-btn>
      </div>
    </v-row>
    <template>
      <v-row>
        <v-select
          width="auto"
          height="50"
          class="mt-8"
          v-model="userType"
          placeholder="Please select user type"
          :items="userarray"
          outlined
        ></v-select>
        <v-col cols="12" sm="6" class="mt-5">
          <v-text-field
            v-model="search"
            hint="search"
            label="Search Name, Email, Phone Number, Sponsor Name"
            v-on:keyup.enter="searchData"
          ></v-text-field>
        </v-col>
        <v-col class="mt-8">
          <v-btn dark medium color="#3c9dcd" @click="searchData"> SEARCH </v-btn>
          <v-btn dark medium color="#3c9dcd" class="ml-3" @click="resetValue">
            RESET
          </v-btn>
        </v-col>
      </v-row>
    </template>
    <div class="text-center">
      <v-progress-circular
        v-show="getLoad"
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <v-data-table
      :headers="headers"
      :items="itemsWithSno"
      :items-per-page="valuePerPage"
      :server-items-length="totalCount"
      class="elevation-1"
      :page.sync="pagination.page"
      @update:options="filterApply"
    >
      <template slot="item.createdAt" slot-scope="props">
        <!-- <v-btn class="mx-2" color="success" @click="() => openDetails(props.item)">view
        </v-btn> -->
        <p v-if="props.item.eventLog">
          {{ formatDate(props.item.eventLog[0].timestamp) }}
        </p>
        <p>-</p>
      </template>

      <template slot="item.assignToken" slot-scope="props">
        <v-btn
          color="primary"
          dark
          v-bind="attrs"
          small
          @click="toggleTokenDailog(props.item)"
        >
          Assign Token
        </v-btn>
      </template>

      <template slot="item.view" slot-scope="props">
        <v-btn
          class="mx-2"
          color="#ea6763"
          dark
          small
          @click="() => openDetails(props.item)"
          >view
        </v-btn>
      </template>
      <template slot="item.delete" slot-scope="props">
        <v-btn
          color="primary"
          dark
          v-bind="attrs"
          small
          @click="toggleDailog(props.item, 'edit')"
        >
          <i class="fa fa-pencil" aria-hidden="true"></i>
        </v-btn>
      </template>
      <template slot="item.userTypes" slot-scope="props">
        <div
          class="typeClass"
          v-for="(val, index) in props.item.userTypes"
          :key="index"
        >
          <p class="type-val">{{ val }}</p>
        </div>
      </template>
    </v-data-table>
    <template>
      <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="600px">
          <v-card v-if="cartType == 'view'">
            <v-card-title>
              <span class="text-h5">User Profile</span>
            </v-card-title>
            <v-card-text>
              <v-simple-table>
                <template v-slot:default>
                  <tr>
                    <th>id: :</th>
                    <td>{{ dialogData._id }}</td>
                  </tr>
                  <tr>
                    <th>Email:</th>
                    <td>{{ dialogData.email }}</td>
                  </tr>
                  <tr>
                    <th>firstName:</th>
                    <td>{{ dialogData.firstName }}</td>
                  </tr>
                  <tr>
                    <th>lastName:</th>
                    <td>{{ dialogData.lastName }}</td>
                  </tr>
                  <tr>
                    <th>phoneNumber:</th>
                    <td>{{ dialogData.phoneNumber }}</td>
                  </tr>
                </template>
              </v-simple-table>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog = false">
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
          <v-card v-else>
            <v-card-title>
              <span class="text-h5">Edit Profile</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      label="First name"
                      required
                      v-model="fName"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      label="Last name"
                      v-model="lName"
                      persistent-hint
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      :value="email"
                      label="Email"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      :value="phoneNumber"
                      label="Phone Number"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      :value="token"
                      label="Token"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      :value="formatDate(createdAt)"
                      label="Createdat"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" md="4">
                    <v-checkbox
                      v-model="checkedNames"
                      label="Employer"
                      color="red"
                      value="Employer"
                      hide-details
                    ></v-checkbox>
                    <v-checkbox
                      v-model="checkedNames"
                      label="Student"
                      color="red darken-3"
                      value="Student"
                      hide-details
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" sm="4" md="4">
                    <v-checkbox
                      v-model="checkedNames"
                      label="Teacher"
                      color="indigo"
                      value="Teacher"
                      hide-details
                    ></v-checkbox>
                    <v-checkbox
                      v-model="checkedNames"
                      label="Parent"
                      color="indigo darken-3"
                      value="Parent"
                      hide-details
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" sm="4" md="4">
                    <v-checkbox
                      v-model="checkedNames"
                      label="School"
                      color="orange"
                      value="School"
                      hide-details
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog = false">
                Close
              </v-btn>
              <v-btn color="blue darken-1" text @click="updateUserData">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <a :href="getExportLink" id="exportlink" />
      <a :href="getOELink" id="allexportlink" />
    </template>
    <template>
      <v-row justify="center">
        <v-dialog v-model="isShow" persistent max-width="600px">
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              Assign Token to
            </v-card-title>

            <v-form class="pa-4">
              <v-text-field
                prepend-icon="mdi-circle-multiple"
                label="Token"
                v-on="tokenRules"
                v-model="tokenAmount"
                type="number"
                max="10"
              ></v-text-field>
              <v-text-field
                prepend-icon="mdi-account"
                label="Sponser Name (Optional)"
                v-model="sponserName"
              ></v-text-field>
              <v-btn
                :disabled="
                  tokenAmount < 1 || tokenAmount > 10 || assignTokenLoading
                "
                @click="addToken(tokenData)"
                class="success mt-3 pa-5"
              >
                <v-progress-circular
                  v-if="assignTokenLoading"
                  indeterminate
                  class="mx-2"
                  color="white"
                ></v-progress-circular>

                Add Token
              </v-btn>
              <v-btn class="success ml-3 mt-3 pa-5" @click="closeDialog()">
                Close
              </v-btn>
            </v-form>
            <v-divider></v-divider>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import API from "../../config";
export default {
  data() {
    return {
      fName: null,
      lName: null,
      uType: null,
      uid: null,
      email: null,
      phoneNumber: null,
      token: null,
      createdAt: null,
      dialog: false,
      isShow: false,
      tokenData: null,
      dialogData: null,
      checkedNames: [],
      cartType: null,
      search: "",
      calories: "",
      valuePerPage: 10,
      pagination: {},
      shortField: null,
      page: 1,
      start: 0,
      end: 5,
      link: null,
      userType: null,
      userarray: ["Student", "Employer", "Teacher", "School", "Parent"],
      sortDesc: false,
      spinner: false,
      tokenRules: [
        (v) => !!v || "This field is required",
        (v) => (v && v > 0) || "Token should be atleast 1",
        (v) => (v && v <= 10) || "Token should be not greater then 10",
      ],
      sponserRules: [(v) => !!v || "This field is required"],
      tokenAmount: "",
      sponserName: "",
      assignTokenLoading: false,
      res: "",
      resData: "",
      snackbar: false,
      tokenSuccess: false,
      tokenDialog: false,
    };
  },
  watch: {
    valuePerPage: function () {
      if (this.shortField && this.userType) {
        console.log("in both condition");
        let p = {
          utype: this.userType,
          sortDesc: this.sortDesc,
          field: this.shortField,
          start: 0,
          limit: this.valuePerPage,
        };
        this.fetchUsersByUserType(p);
        this.fetchUsersLengthByUserType(p);
      } else if (this.shortField) {
        console.log("in shortField condition");
        let p = {
          sortDesc: this.sortDesc,
          field: this.shortField,
          start: 0,
          limit: this.valuePerPage,
        };
        this.fetchFilterUser(p);
      } else if (this.userType) {
        console.log("in userType condition");
        let p = {
          utype: this.userType,
          sortDesc: this.sortDesc,
          start: 0,
          limit: this.valuePerPage,
        };
        this.fetchUsersByUserType(p);
        this.fetchUsersLengthByUserType(p);
      } else {
        let perPage = this.valuePerPage;
        if (this.valuePerPage < 0) {
          perPage = this.getUsersData.meta.total_count;
        }
        console.log("in else condition",perPage);
        let p = { start: 0, limit: perPage };
        this.fetchUsersData(p);
      }
    },

    shortField: function () {
      this.spinner = true;
      if (this.userType) {
        let p = {
          utype: this.userType,
          sortDesc: this.sortDesc,
          field: this.shortField,
          start: this.valuePerPage * this.page - this.valuePerPage,
          limit: this.valuePerPage,
        };
        this.fetchUsersByUserType(p);
        this.fetchUsersLengthByUserType(p);
      } else {
        let p = {
          sortDesc: this.sortDesc,
          field: this.shortField,
          start: this.valuePerPage * this.page - this.valuePerPage,
          limit: this.valuePerPage,
        };
        this.fetchFilterUser(p);
      }
      this.spinner = false;
    },

    page: function async() {
      this.spinner = true;
      console.log(this.shortField, "in page- valuePerPage");
      console.log(this.shortField, this.userType, "utttttttttttttt");
      if (this.search && this.search.length > 2) {
        let p = {
          start: this.valuePerPage * this.page - this.valuePerPage,
          limit: this.valuePerPage,
          search: this.search,
        };
        this.fetchUserSearch(p);
      } else if (this.shortField && this.userType) {
        console.log("in both condition");
        let p = {
          utype: this.userType,
          sortDesc: this.sortDesc,
          field: this.shortField,
          start: this.valuePerPage * this.page - this.valuePerPage,
          limit: this.valuePerPage,
        };
        this.fetchUsersByUserType(p);
        this.fetchUsersLengthByUserType(p);
      } else if (this.search && this.userType) {
        console.log("in both condition");
        let p = {
          start: this.valuePerPage * this.page - this.valuePerPage,
          limit: this.valuePerPage,
          search: this.search,
          userType: this.userType,
        };
        this.fetchUserSearch(p);
        this.fetchUserSearchLength(p);
      } else if (this.shortField) {
        console.log("in shortField condition");
        let p = {
          sortDesc: this.sortDesc,
          field: this.shortField,
          start: this.valuePerPage * this.page - this.valuePerPage,
          limit: this.valuePerPage,
        };
        this.fetchFilterUser(p);
      } else if (this.userType) {
        console.log("in userType condition");
        let p = {
          utype: this.userType,
          sortDesc: this.sortDesc,
          start: this.valuePerPage * this.page - this.valuePerPage,
          limit: this.valuePerPage,
        };
        this.fetchUsersByUserType(p);
        this.fetchUsersLengthByUserType(p);
      } else {
        console.log("in else condition");
        let p = {
          start: this.valuePerPage * this.page - this.valuePerPage,
          limit: this.valuePerPage,
        };
        this.fetchUsersData(p);
      }
      this.spinner = false;
    },
    userType: function () {
      this.spinner = true;
      this.pagination.page = 1;
      this.page = 1;
      console.log(this.userType, "this.userType");
      if (this.userType && this.search) {
        this.page = 1;
        let p = {
          start: this.valuePerPage * this.page - this.valuePerPage,
          limit: this.valuePerPage,
          search: this.search,
          userType: this.userType,
        };
        this.fetchUserSearch(p);
        this.fetchUserSearchLength(p);
      } else {
        let p = {
          utype: this.userType,
          sortDesc: this.sortDesc,
          field: this.shortField,
          start: 0,
          limit: this.valuePerPage,
        };
        this.fetchUsersByUserType(p);
        this.fetchUsersLengthByUserType(p);
      }
      this.spinner = false;
    },

    sortDesc: function () {
      this.spinner = true;
      let p = {
        sortDesc: this.sortDesc,
        field: this.shortField,
        start: this.valuePerPage * this.page - this.valuePerPage,
        limit: this.valuePerPage,
      };
      this.fetchFilterUser(p);
      this.spinner = false;
    },
  },
  computed: {
    headers() {
      return [
        { text: "Serial No", value: "sno", sortable: false },
        { text: "First Name", value: "firstName" },
        { text: "Last Name", value: "lastName" },
        { text: "Email", value: "email" },
        { text: "Mobile", value: "phoneNumber", sortable: false },
        {
          text: "User Types",
          value: "userTypes",
          sortable: false,
          class: "section-calories ",
        },
        { text: "Sponsor Name", value: "sponsorName", sortable: false },
        { text: "Tokens", value: "tokensCount", sortable: false },
        { text: "Created At", value: "createdAt" },
        { text: "", value: "assignToken", sortable: false },
        { text: "", value: "view", sortable: false },
        { text: "", value: "delete", sortable: false },
        { text: "", value: "v", sortable: false },
      ];
    },
    // searchTrigger() {
    //   return this.search.length < 4 ? "" : this.search;
    // },
    itemsWithSno() {
      return this.body.map((item, index) => ({
        ...item,
        firstName: item.firstName ? item.firstName : "-",
        lastName: item.lastName ? item.lastName : "-",
        email: item.email ? item.email : "-",
        phoneNumber: item.phoneNumber ? item.phoneNumber : "-",
        userTypes: item.userTypes ? item.userTypes : "",
        sponsorName: item.sponsorName ? item.sponsorName : "-",
        tokensCount: item.tokensCount ? item.tokensCount : 0,
        sno: index + 1 + (this.page - 1) * this.valuePerPage,
      }));
    },
    ...mapGetters("Employee", [
      "getUsersData",
      "getLoad",
      "getExportLink",
      "getOELink",
      "getUsersTotalCount",
    ]),
    body() {
      return this.getUsersData.data;
    },
    totalCount() {
      if (this.search && this.search !== null) {
        return this.getUsersTotalCount;
      } else if (this.userType) {
        return this.getUsersTotalCount;
      } else {
        return this.getUsersData.meta.total_count;
      }
    },
  },
  methods: {
    async addToken(value) {
      this.assignTokenLoading = true;

      try {
        let Response = await axios.post(
          `${API.REDIS_API}/token/create-tokens/`,
          {
            customer_id: value.stripeId,
            quantity: this.tokenAmount,
            user_id: value._id,
            sponsorName: this.sponserName != "" ? this.sponserName : undefined,
          }
        );

        this.assignTokenLoading = false;
        this.res = Response.data.statusCode;
        this.resData = Response.data.data.description;
        console.log("response " + this.res);

        this.snackbar = true;
        if (this.res == 200) {
          this.tokenSuccess = true;
          this.sponserName = "";
          this.tokenAmount = "";
        }
        if (this.tokenSuccess && this.snackbar) {
          this.isShow = false;
          this.tokenAmount = "";
          this.sponserName = "";
          this.spinner = true;
          console.log("in update method");

          // this.updateUserData();
          setTimeout(() => {
            this.conditionMethod();
          }, 4000);
        }
      } catch (error) {
        this.assignTokenLoading = false;
        this.snackbar = true;
      }
      this.$store.dispatch("fetchUsersOverAllExport");
    },

    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleUpperCase().indexOf(search) !== -1
      );
    },
    NameFilter() {
      console.log("in namefilter");
      let p = {
        sortDesc: this.sortDesc,
        field: this.shortField,
        page: this.valuePerPage,
      };
      this.fetchFilterUser(p);
    },
    updateIron(val) {
      console.log(val, "updateIron");
    },
    filterApply(value) {
      console.log(value, "filteapply");
      if (value == "eventLog[0].event") {
        this.shortField = "createdAt";
      } else {
        this.shortField = value.sortBy[0];
      }
      this.valuePerPage = value.itemsPerPage;
      this.page = value.page;
      this.sortDesc = value.sortDesc[0];
    },
    openDetails(val) {
      this.$router.push(
        "detail/" +
          val._id +
          "/" +
          val.email +
          "/" +
          val.firstName +
          "/" +
          val.lastName
      );
    },
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear(),
        hour = d.getHours(),
        min = d.getMinutes();

      var ampm = hour >= 12 ? "pm" : "am";
      hour = hour % 12;
      hour = hour ? hour : 12; // the hour '0' should be '12'
      min = min < 10 ? "0" + min : min;
      var strTime = hour + ":" + min + " " + ampm;

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return `${day}-${month}-${year} ${strTime}`;
    },
    conditionMethod() {
      console.log("in condition method");
      if (this.shortField && this.userType) {
        console.log("in both condition");
        let p = {
          utype: this.userType,
          sortDesc: this.sortDesc,
          field: this.shortField,
          start: this.valuePerPage * this.page - this.valuePerPage,
          limit: this.valuePerPage,
        };
        this.fetchUsersByUserType(p);
        this.fetchUsersLengthByUserType(p);
        this.spinner = false;
      } else if (this.shortField) {
        console.log("in shortField condition");
        let p = {
          sortDesc: this.sortDesc,
          field: this.shortField,
          start: this.valuePerPage * this.page - this.valuePerPage,
          limit: this.valuePerPage,
        };
        this.fetchFilterUser(p);
        this.spinner = false;
      } else if (this.search.length >= 4 && this.userType) {
        this.spinner = true;
        this.page = 1;
        let p = {
          start: this.valuePerPage * this.page - this.valuePerPage,
          limit: this.valuePerPage,
          search: this.search,
          userType: this.userType,
        };
        this.fetchUserSearch(p);
        this.fetchUserSearchLength(p);
        this.spinner = false;
      } else if (this.search.length >= 4) {
        this.spinner = true;
        this.userType = null;
        this.page = 1;
        let p = {
          start: this.valuePerPage * this.page - this.valuePerPage,
          limit: this.valuePerPage,
          search: this.search,
        };
        this.fetchUserSearch(p);
        this.fetchUserSearchLength(p);
        this.spinner = false;
      } else if (this.userType) {
        console.log("in userType condition");
        let p = {
          utype: this.userType,
          sortDesc: this.sortDesc,
          start: this.valuePerPage * this.page - this.valuePerPage,
          limit: this.valuePerPage,
        };
        this.fetchUsersByUserType(p);
        this.fetchUsersLengthByUserType(p);
        this.spinner = false;
      } else {
        console.log("in else condition");
        let p = {
          start: this.valuePerPage * this.page - this.valuePerPage,
          limit: this.valuePerPage,
        };
        this.fetchUsersData(p);
        this.spinner = false;
      }
    },
    fetchDataGrid() {
      this.spinner = false;
    },
    toggleDailog(val, cartType) {
      let data = val;
      this.dialogData = val;
      this.dialog = !this.dialog;
      this.uid = data._id;
      this.email = data.email;
      this.phoneNumber = data.phoneNumber;
      this.token = data.tokensCount;
      this.createdAt = data.eventLog[0].timestamp;
      this.fName = data.firstName;
      this.lName = data.lastName;
      this.checkedNames = data.userTypes.length > 0 ? data.userTypes : [];
      this.cartType = cartType;
      console.log(val, "value", data._id);
    },
    toggleTokenDailog(value) {
      this.isShow = !this.isShow;
      this.tokenData = value;
    },
    async exportData() {
      let p = {
        utype: this.userType,
        sortDesc: this.sortDesc,
        field: this.shortField,
        start: this.valuePerPage * this.page - this.valuePerPage,
        limit: this.valuePerPage,
        search: this.searchTrigger,
      };
      return await this.fetchUsersExport(p);
    },
    async downloadData() {
      this.link = await this.exportData();
      console.log(this.link, "link");
      if (this.link) {
        window.open(this.link, "_blank");
      }
      this.snackbar = true;
    },
    async overAlldownloadData() {
      this.link = await this.fetchUsersOverAllExport();
      if (this.link) {
        window.open(this.link, "_blank");
      }
      this.snackbar = true;
    },
    closeDialog() {
      this.isShow = false;
      this.tokenAmount = "";
      this.sponserName = "";
    },
    searchData() {
      console.log(this.search, this.userType);
      let length = this.search.length;
      if (length >= 4 && this.userType) {
        this.spinner = true;
        this.page = 1;
        let p = {
          start: this.valuePerPage * this.page - this.valuePerPage,
          limit: this.valuePerPage,
          search: this.search,
          userType: this.userType,
        };
        this.fetchUserSearch(p);
        this.fetchUserSearchLength(p);
        this.spinner = false;
      } else if (length >= 4) {
        this.spinner = true;
        this.userType = null;
        this.page = 1;
        let p = {
          start: this.valuePerPage * this.page - this.valuePerPage,
          limit: this.valuePerPage,
          search: this.search,
        };
        this.fetchUserSearch(p);
        this.fetchUserSearchLength(p);
        this.spinner = false;
      } else if (this.search.length < 1) {
        let p = {
          start: 0,
          limit: this.valuePerPage,
        };
        this.page = 1;
        this.fetchUsersData(p);
        this.search = "";
        this.userType = null;
      }
    },
    updateUserData() {
      this.spinner = true;
      console.log("in update method");
      let p = {
        id: this.uid,
        fname: this.fName,
        lname: this.lName,
        utype: this.checkedNames,
      };
      console.log(p, "data");
      this.updateUserRecord(p);
      setTimeout(() => {
        this.conditionMethod();
      }, 2000);
      this.dialog = false;
    },
    resetValue() {
      let p = {
        start: 0,
        limit: this.valuePerPage,
      };
      this.page = 1;
      this.fetchUsersData(p);
      this.search = "";
      this.userType = null;
    },
    ...mapActions("Employee", [
      "fetchUsersData",
      "fetchFilterUser",
      "fetchUsersByUserType",
      "fetchUsersExport",
      "fetchUsersOverAllExport",
      "updateUserRecord",
      "fetchUserSearch",
      "fetchUserSearchLength",
      "fetchUsersLengthByUserType",
    ]),
  },
  created() {
    if (this.getUsersData == null) {
      console.log("call home & export api");
      let p = {
        start: this.valuePerPage * this.page - this.valuePerPage,
        limit: this.valuePerPage,
      };
      this.fetchUsersData(p);
    }
    this.isAuth = this.$store.getters.getAuth;
    if (!this.isAuth) {
      this.$router.push("/login");
      setTimeout(() => {
        this.apexLoading = true;
      });
    }
  },
};
</script>

<style scoped>
.typeClass {
  display: flex !important;
}

.type-val {
  display: flex;
  background-color: #90caf9;
}

.section-calories {
  color: green !important;
}

.select {
  width: 60px;
  max-height: 60px;
  font-size: 11px;
}

.v-input.theme--light.v-text-field.v-text-field--is-booted.v-text-field--enclosed.v-text-field--outlined.v-text-field--placeholder.v-select {
  max-width: 25%;
}

table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
}
th,
td {
  padding: 5px;
  text-align: left;
}
</style>
